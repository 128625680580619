@import "@/styles/variables";
@import "@/styles/functions";
@import "@/styles/new-variables";

$gutter: 1rem;

.modalContainer {
  position: absolute;
  left: 50%;
  top: auto;
  bottom: calc($gutter / 2);
  transform: translateX(-50%);
  width: calc(100% - $gutter);

  @media (min-width: get-breakpoint-width("sm")) {
    top: 50%;
    left: 50%;
    bottom: auto;
    transform: translate(-50%, -50%);
  }
}

.modalContainer {
  &.sm {
    max-width: calc(#{get-breakpoint-width("sm")} - #{$gutter});
  }

  &.md {
    max-width: calc(#{get-breakpoint-width("md")} - #{$gutter});
  }

  &.lg {
    max-width: calc(#{get-breakpoint-width("lg")} - #{$gutter});
  }

  &.xl {
    max-width: calc(#{get-breakpoint-width("xl")} - #{$gutter});
  }
}

.modal {
  position: relative;
  border-radius: 1.25rem;
  box-shadow: $image-box-shadow;
  outline: none;
  border: 1px solid rgba(255, 255, 255, 0.06);
  background: $black;
  backdrop-filter: blur(15px);
}

.modalHeader {
  display: flex;
  align-items: center;
  padding: 1rem;
}

.modalBody,
.modalFooter {
  padding: 1rem;
}

.modalCloseButton {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(-50%, calc(50% - 0.5rem));
  padding: 0.5rem;
  transition: color 0.2s;
  z-index: 10;
}
