@import '@/styles/new-variables';

.profileContent {
  gap: 1.25rem;
  padding: 2rem 0;

  .topPanel {
    margin-bottom: 1rem;
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    gap: 1.25rem;
  }

  .profileSidebar {
    display: none;
    visibility: hidden;
    opacity: 0;

    .sidebarNav {
      position: sticky;
      top: $desktop-header-height;
    }
  }
}

@media (min-width: get-breakpoint-width('lg')) {
  .profileContent {
    display: grid;
    grid-template-columns: toRem(280) calc(100% - toRem(300));

    .profileSidebar {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
}
